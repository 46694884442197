@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
    font-display: swap;
    font-family: 'Monaco';
    font-style: normal;
    font-weight: normal;
    src: local('Monaco'), url('../fonts/Monaco.woff') format('woff');
}

body {
    font-family: Monaco, 'Lucida Grande', 'sans-serif';
}
